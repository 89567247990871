import { useEmblaCarousel } from 'embla-carousel/react'
import chunk from 'lodash/chunk'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import tw, { css } from 'twin.macro'
import { StyleType, SVGType } from '../utils/prop-types'

const LogoGroup = ({ group }) => (
  <div
    css={tw`relative min-w-full justify-items-center items-center content-start grid grid-cols-2 gap-x-8 gap-y-16 lg:grid-cols-4`}
  >
    {group.map((logo) => (
      <img loading="lazy" key={logo.url} src={logo.url} alt="" css={tw`max-h-logo`} />
    ))}
  </div>
)

LogoGroup.propTypes = {
  group: PropTypes.arrayOf(PropTypes.object),
}

const LogoGrid = ({ logos, style }) => {
  const [viewportRef, embla] = useEmblaCarousel({ loop: true, speed: 5 })
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])
  const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [embla])
  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
  }, [embla, setSelectedIndex])

  useEffect(() => {
    if (!embla) return
    onSelect()
    setScrollSnaps(embla.scrollSnapList())
    embla.on('select', onSelect)
  }, [embla, setScrollSnaps, onSelect])

  const mobileGrouping = chunk(logos, 6)
  const SlideIndicator = ({ selected, onClick }) => (
    <button
      css={css`
        width: 10px;
        height: 10px;
        ${tw`rounded-full bg-primary-500 bg-opacity-20`}
        ${selected && tw`bg-opacity-100`}
      `}
      type="button"
      onClick={onClick}
    />
  )

  SlideIndicator.propTypes = {
    selected: PropTypes.bool,
    onClick: PropTypes.func,
  }

  return (
    <div css={[tw`flex flex-col`, style]}>
      <div css={tw`hidden lg:block`}>
        <LogoGroup group={logos} />
      </div>
      <div
        css={[
          css`
            ${tw`overflow-hidden lg:hidden`}
            &.is-draggable {
              ${tw`cursor-grab`}
            }
            &.is-dragging {
              ${tw`cursor-grabbing`}
            }
          `,
        ]}
        ref={viewportRef}
      >
        <div css={tw`flex`}>
          {mobileGrouping.map((group, i) => (
            <LogoGroup key={i} group={group} />
          ))}
        </div>
      </div>
      <div
        css={[
          tw`space-x-3 mt-12 flex w-full justify-center lg:hidden`,
          scrollSnaps.length <= 1 && tw`hidden`,
        ]}
      >
        {scrollSnaps.map((_, index) => (
          <SlideIndicator
            key={index}
            selected={index === selectedIndex}
            onClick={() => scrollTo(index)}
          />
        ))}
      </div>
    </div>
  )
}

LogoGrid.propTypes = {
  logos: PropTypes.arrayOf(SVGType).isRequired,
  style: StyleType,
}

export default LogoGrid
