import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'
import { HTMLType, LinkType, StyleType } from '../utils/prop-types'
import Button from './button'
import Heading from './heading'

const LinkBlock = ({
  title,
  subtitle,
  description,
  callToAction,
  modal,
  handleClick,
  titleHeading,
  subtitleHeading,
  descriptionHeading,
  buttonSize,
  buttonTheme,
  style,
}) => {
  return (
    <div css={[tw`flex flex-col items-start`, style]}>
      <Heading headingType={titleHeading} content={title} style={tw`font-bold`} />
      <Heading headingType={subtitleHeading} content={subtitle} style={tw`mt-4 lg:mt-6`} />
      <Heading headingType={descriptionHeading} content={description} style={tw`mt-4 lg:mt-6`} />
      {callToAction && (
        <Button
          style={tw`mt-10 lg:mt-12`}
          type="primary"
          size={buttonSize}
          theme={buttonTheme}
          handleClick={handleClick}
          label={callToAction.label}
          link={callToAction.link}
          disabled={callToAction.disabled}
          modal={modal}
        />
      )}
    </div>
  )
}

LinkBlock.propTypes = {
  title: HTMLType,
  subtitle: HTMLType,
  description: HTMLType,
  callToAction: LinkType,
  titleHeading: PropTypes.string,
  subtitleHeading: PropTypes.string,
  descriptionHeading: PropTypes.string,
  buttonSize: PropTypes.oneOf(['xs', 'sm', 'base', 'lg']),
  buttonTheme: PropTypes.string,
  style: StyleType,
}

LinkBlock.defaultProps = {
  titleHeading: 'h3',
  subtitleHeading: 'h5',
  descriptionHeading: 'h6',
  buttonSize: 'base',
}

export default LinkBlock
